#generateDownloadCv {
  cursor: pointer;
}

.entry-meta.entry-meta-bottom {
  border-radius: 6px!important;
  border: none!important;
  padding: 5px 10px!important;
}

.blog-masonry{
  cursor: pointer;
}
.header-titles h4 {
  margin: 12px 0!important;
  line-height: 1.4em!important;
}
.dialog{
  font-family: 'Poppins', Helvetica, sans-serif!important;
}

.no_comments{
  margin-bottom: 4rem!important;
  margin-top: -1rem!important;
  color: #a00404!important;
  font-weight: 700!important;
}

.user_img img {
    height: 70px;
    width: 70px;
    min-width: 60px;
    border-radius: 50px;
}
.user_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_name_date {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.user_comment_data .col_user {
  padding: 0;
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #eee;
}
.user_comment_data {
  margin-bottom: 2rem;
  margin-left: inherit;
  margin-right: inherit;
}
.comment_title{
  margin-bottom: 1.5rem!important;
}
.user_name_date .user_name{
  color: #6a6868;
  font-weight: 700;
}
.user_name_date .cmnt_date {
  color: #aaa;
  font-size: 13px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
  padding-left: 8px;
}
.post-thumbnail {
  max-height: 300px;
  overflow: hidden;
  border-radius: 7px;
  background: none!important;
  display: flex!important;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #f4f2f2;
}
.entry-header{
  margin-bottom: 3rem;
}
.entry-content{
  margin-top: 3rem;
}
.postSpinner {
  display: flex!important;
  align-items: flex-start;
  justify-content: center;
}

.share-buttons a{
  border-radius: 5px!important;
}
.next,.prev{
  margin-bottom: 15px;
  color: #5e2fd6;
}
.blog_cat{
  color: #9a9a9a;
  font-size: 12px;
}
.btn_for_prev_next{
    background-color: #fcfcfc;
    margin: 1rem 0 4rem 0;
    border-radius: 5px;
}

.col_btn_next, .col_btn_prev{
  padding: 10px;
}

.btn_for_prev_next .btn_for_prev, .btn_for_prev_next .btn_for_next{
  padding: 5px;
  cursor: pointer;
}
.btn_for_prev_next .btn_for_next{
  text-align: right;
  border-left: 1px solid #eee;
}
.lm-info-block .lm-info-block-value {
  font-size: 25px !important;
}

#portfolioGrid {
  overflow: unset !important;
}

#shutImageHead {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  width: 100px !important;
  height: 100px !important;
  overflow: hidden;
  border-radius: 50% !important;
  border: 3px solid #fff;
  align-content: flex-start;
  flex-wrap: wrap;
}

#shutImageHead img {
  height: auto;
  width: 100%;
  background-color: unset !important;
  border-radius: unset;
  border: none;
}

.messages {
  color: red;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

#shutDownHead,
#shutPageContent {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}



.page {
  display: block;
  width: 100%;
  height: 100vh !important;
  /* This will set the height to the full height of the viewport */
  min-height: 100vh !important;
  /* Ensures that it always takes up at least the full viewport height */
  padding: 0 100px;
  overflow: hidden;
}


#shutDownPage {
  background-color: #5050f5;
  background: -webkit-gradient(linear, left top, left bottom, from(#9d2bf3), to(#5050f5)) !important;
  background: linear-gradient(#9d2bf3, #5050f5) !important;
  /* margin: 10rem 0 0 0; */
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 15px;
  font-family: 'Poppins', Helvetica, sans-serif;
  line-height: 1.65em;
  overflow: hidden;
  color: #d5d5d5;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter__time span:nth-child(2) {
  font-size: 15px;
}

.portfolio-grid.three-columns.shuffle {
  height: 1000px !important;
}

.header-titles {
  padding: 15px;
}

.menu_li_item {
  cursor: pointer;
}

.cv_download_button {
  cursor: pointer;
}

.header-buttons,
.header .copyrights a {
  position: relative;
  z-index: 2;
}

.header-buttons {
  margin-top: 40px !important;
}

.lmpixels-arrows-nav:hover {
  outline: 1px solid #5e2fd6;
}

.lmpixels-arrows-nav {
  cursor: pointer !important;
  position: fixed !important;
  right: 50% !important;
  padding: 0px !important;
  transform: translate(50%, 0) !important;
  z-index: 99999 !important;
  bottom: 0 !important;
  display: inline-flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  border: none !important;
  border-radius: 2rem 2rem 0 0 !important;
  box-shadow: none !important;
  height: 4rem !important;
  flex-direction: column !important;
  width: 3rem !important;
}

.lmpixels-arrows-nav div {
  padding: 0 !important;
  position: inherit !important;
}

/* For Down arrow */

/* .lmpixels-arrows-nav {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 z-index: 9999;
 height: 100vh;
} */

.chevron {
  position: absolute;
  width: 2.1rem;
  height: 0.5rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #5e2fd6;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

/* For Down arrow */

.certificate-item {
  cursor: pointer !important;
  min-height: 200px;
}

.about_me_text {
  text-align: justify !important;
}

.portfolio-grid h4 {
  font-family: Poppins, Helvetica, sans-serif, "Noto Serif Bengali", serif;
}

/* .copyrights a {
  color: blue;
} */
/* .certi-title{
  overflow: overlay;
  white-space: nowrap;
  height: 40px;
} */
.menu-toggle span:nth-child(1) {
  top: 13px;
}

.menu-toggle span:nth-child(2) {
  top: 22px;
  width: 15px;
}

.menu-toggle span:nth-child(3) {
  top: 30px;
}

.portfolio-item-img {
  display: flex;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px;
  background: rgb(255 255 255);
}

.lm-info-block {
  border-radius: 10px;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 20px;
  bottom: 0;
  flex-direction: column-reverse;
}

.counterTimer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.textShutPage {
  font-size: 15px;
  color: rgb(221, 221, 221);
  text-align: center;
  margin-bottom: 20px;
}

.textShutPage p {
  margin-bottom: -10px;
  padding: 0;
  font-size: 11px;
  color: rgb(255 255 255);
  text-align: center;
}

.counter__time span:nth-child(1) {
  font-weight: bold;
  font-size: 40px;
  color: rgb(255, 255, 255);
}

.counter__time {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-grid figure .category,
.portfolio-grid figure i {
  border-radius: 5px;
}

.portfolio-item-img img {
  height: 150px;
  width: 150px !important;
}

.lm-info-block .lm-info-block-value {
  font-size: 20px;
}

.skills-second-style .skill-percentage {
  height: 4px !important;
  width: 100% !important;
  /* background-color: #ddd!important; */
}

.skills-second-style .skill-container {
  border: none !important;
}


.skills-second-style .skill-container.skill-100 .skill-percentage {
  width: 100%;
}

.skills-second-style .skill-container.skill-95 .skill-percentage {
  width: 95%;
}

.skills-second-style .skill-container.skill-90 .skill-percentage {
  width: 90%;
}

.skills-second-style .skill-container.skill-85 .skill-percentage {
  width: 85%;
}

.skills-second-style .skill-container.skill-80 .skill-percentage {
  width: 80%;
}

.skills-second-style .skill-container.skill-75 .skill-percentage {
  width: 75%;
}

.skills-second-style .skill-container.skill-70 .skill-percentage {
  width: 70%;
}

.skills-second-style .skill-container.skill-65 .skill-percentage {
  width: 65%;
}

.skills-second-style .skill-container.skill-60 .skill-percentage {
  width: 60%;
}

.skills-second-style .skill-container.skill-55 .skill-percentage {
  width: 55%;
}

.skills-second-style .skill-container.skill-50 .skill-percentage {
  width: 50%;
}

.skeletonProfile {
  height: 186px !important;
  border-radius: 3rem !important;
  width: 150px !important;
  background-color: rgb(255 255 255) !important;
}

.skeletonProfileMaintenace {
  height: 100px !important;
  border-radius: 50% !important;
  width: 100px !important;
  background-color: rgb(255 255 255) !important;
}

.skeletonName {
  width: 16rem;
  border-radius: .5rem;
  margin: 0 auto;
  height: 2.7rem !important;
  background-color: rgb(255 255 255 / 50%) !important;
}

.skeletonTag {
  width: 12rem;
  border-radius: .5rem;
  margin: 0 auto;
  height: 1.9rem !important;
  background-color: rgb(255 255 255 / 50%) !important;
}

.spinnerBar {
  display: flex !important;
  height: 100% !important;
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -8%) !important;
  align-items: center !important;
}

.spinnerBarMaintenace {
  display: flex !important;
  height: 100% !important;
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  align-items: center !important;
}

.spinnerBarLoading {
  display: flex !important;
  height: 100% !important;
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  align-items: center !important;
}

div#profilePhoto img {
  width: 150px;
}

div#profilePhoto {
  display: flex !important;
  justify-content: center !important;
  /* background: #fff; */
  flex-direction: row;
  position: relative !important;
  /* width: 175px !important;
  height: 175px !important;
  overflow: hidden;
  border-radius: 50% !important; 
  border: 3px solid #fff; */
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto 3rem;
  min-height: 186px;
}

.header-photo img {
  border-radius: 3rem !important;
}

.header {
  padding: 60px 10px 10px 10px !important;
}

.header-titles h2 {
  font-size: 30px !important;
}

.title-block h2 {
  font-size: 50px !important;
}

.section-content {
  overflow: auto;
  height: 100vh !important;
}

.sp-subtitle {
  height: 2rem;
  font-size: 17px;
}

.social-links {
  z-index: 1;
  position: relative;
}

.form-control,
.form-control:focus,
.has-error .form-control,
.has-error .form-control:focus,
input[type=password],
input[type=search],
input[type=text] {
  border: none !important;
}

.form_contact input {
  padding: 16.5px 14px !important;
}

.MuiInputBase-formControl {
  border-radius: 1rem !important;
}

.portfolio-grid figure i {
  top: 0 !important;
  right: 0 !important;
}

.itemFilterPort {
  cursor: pointer;
}

.contactMessage {
  margin-top: 2rem !important;
}

li.menu_li_item.active span {
  color: #5e2fd6;
  font-weight: 500;
}

/* li.menu_li_item.active {
  border-bottom: 2px solid #5e2fd6;
} */

.main_body_wrapper .row:last-child {
  margin-bottom: 10rem;
}

.expDesc {
  margin-top: .5rem;
  line-height: 1.9;
}

div#shutDownHead {
  position: absolute;
  top: 5rem;
}

.lm-animated-bg {
  position: fixed !important;
}

.lm-animated-bg,
.content-area {
  z-index: 1 !important;
}

.home_text {
  text-align: left;
}

.home_name {
  font-size: 50px;
}

.home_inner img {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.home_photo {
  position: relative;
  width: 300px;
  background-color: #fff;
  border-radius: 1000px;
  border: 18px solid #fff;
  overflow: hidden;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
  height: 300px;
}

.hp-dots {
  position: absolute;
  opacity: 0.04;
  top: 16%;
  left: 6%;
  content: '';
  height: 28%;
  width: 16%;
  background-size: 18px 18px;
  z-index: -1;
  background-image: -webkit-repeating-radial-gradient(center center, #000, #000 3px, transparent 0px, transparent 100%);
}

.blog-card .media-block img {
  /* border-radius: 10px 10px 0px 0px !important; */
}

.blog-card .media-block {
  border-radius: 10px 10px 0px 0px !important;
}

.blog-card .post-info {
  border: 1px solid #dfdfdf !important;
  border-radius: 0px 0px 10px 10px !important;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.3rem!important;
}

.blog-card .category a {
  border-radius: 5px !important;
}

.not_found_blog {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #5e2fd6;
  font-weight: 500;
  font-size: 20px;
}

.not_found_blog .lnr-book:before {
  font-size: 75px;
}

.no_title {
  margin-top: 20px;
}

.skeletonProfileHome {
  height: 300px !important;
  width: 300px !important;
}

.mobile-visible {
  display: none;
  visibility: hidden;
}

.blog_body_text ul,
.blog_body_text ol {
  padding-left: 40px;
}

.blog_body_text h1,
.blog_body_text h2,
.blog_body_text h3,
.blog_body_text h4,
.blog_body_text p,
.blog_body_text ul,
.blog_body_text ol,
.blog_body_text li,
.blog_body_text figure,
.blog_body_text figcaption,
.blog_body_text blockquote,
.blog_body_text dl,
.blog_body_text dd {
  margin: inherit;
}

/* .grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  right: -35px !important;
  bottom: 1px!important;
  position: absolute!important;
  transform: translate(0%, 345%);
}

.grecaptcha-badge:hover {
  width: 256px !important;
} */


@media only screen and (max-width: 1280px) {
  .header {
    max-width: 350px !important;
  }
}

@media only screen and (max-width: 1200px) {
  div#profilePhoto {
    width: 150px !important;
    height: 150px !important;
  }

  .header-titles h2 {
    font-size: 30px !important;
  }

  .title-block h2 {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 1025px) {
  .home_photo {
    width: 275px;
    height: 275px;
    border: 10px solid #fff;
  }
  .section-content {
    padding: 85px 30px!important;
}
  .hp-dots {
    top: 6%;
    left: 28%;
    height: 34%;
    width: 22%;
}
  .image_grid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px !important;
  }

  .mobile-site-title a, .mobile-site-title a:hover, .mobile-site-title a:focus {
    position: absolute;
    display: inline-block;
    font-weight: 600;
    padding: 10px;
    z-index: 99;
    left: 20px;
    top: 10px;
    border-radius: 5px;
    background-color: rgba(255,255,255,.8);
    font-size: 18px;
    color: #222;
}

  .mobile-visible {
    display: block;
    visibility: visible;
}

  .home_text {
    text-align: center;
  }

  .header-photo {
    max-width: 100% !important;
  }

  li.menu_li_item.active span {
    color: #ffffff;
    font-weight: 500;
  }

  div#profilePhoto {
    width: 130px !important;
    height: 130px !important;
  }

  .header-titles h2 {
    font-size: 25px !important;
  }

  .title-block h2 {
    font-size: 45px !important;
  }

  ul.main-menu .nav-anim {
    display: block;
    color: #fff;
    padding: 12px 0;
    font-weight: normal;
    line-height: 21px;
    opacity: .7;
  }

  ul.main-menu li.menu_li_item.active .nav-anim {
    opacity: 1 !important;
  }

}

@media only screen and (max-width: 768px) {

  /* .grecaptcha-badge {
    right: 0px !important;
    transform: translate(0%, 382%);
} */

.user_img {
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.user_name_date {
  flex-direction: column;
  align-items: flex-start;
  /* position: absolute; */
  right: 10px;
  top: -75px;
}

.user_name_date .cmnt_date {
  margin-left: inherit;
  border-left: none;
  padding-left: inherit;
}

.btn_for_prev_next .btn_for_prev, .btn_for_prev_next .btn_for_next{
text-align: center;
}
.btn_for_prev_next .btn_for_next{
  border-left: none;
  border-top: 1px solid #eee;
}
  .hp-dots {
    top: 9%;
    left: 20%;
    height: 24%;
    width: 26%;
}
  .home_photo {
    width: 225px;
    height: 225px;
    border: 10px solid #fff;
  }

  .not_found_blog {
    font-size: 15px;
  }

  .home_name {
    font-size: 36px;
  }

  div#shutDownHead {
    position: absolute;
    top: 2rem;
  }

  .skeletonName {
    width: 13rem;
    border-radius: .4rem;
    height: 2rem !important;
    background-color: rgb(255 255 255 / 50%) !important;
  }

  .skeletonTag {
    width: 10rem;
    border-radius: .4rem;
    height: 1.5rem !important;
    background-color: rgb(255 255 255 / 50%) !important;
  }

  .title-block h2 {
    font-size: 32px !important;
    line-height: 35px !important;
  }

  li.menu_li_item.active {
    border-bottom: unset;
  }

  .header-photo img {
    border-radius: 2rem !important;
  }

  .header {
    padding: 30px 10px 10px 10px !important;
    max-width: 300px !important;
  }

  .section-content {
    padding: 5rem 1.5rem !important;
  }

  .professional_class {
    margin-top: 50px;
  }

  div#profilePhoto {
    width: 100px !important;
    height: 100px !important;
    min-height: 124px !important;
  }

  .skeletonProfile {
    height: 100px !important;
    width: 100px !important;
    min-height: 124px !important;
    border-radius: 2rem !important;
  }

  .skeletonProfileHome {
    height: 200px !important;
    width: 200px !important;
    border-radius: 30% !important;
  }

  .counterTimer {
    gap: 25px !important;
    bottom: 50px;
  }

  .counter__time span:nth-child(1) {
    font-size: 25px !important;
  }

  .counter {
    bottom: 0;
  }

  .header-titles {
    padding: 0 1rem;
  }

  .lm-info-block .lm-info-block-value {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 560px) {

  .home_photo {
    width: 175px;
    height: 175px;
    border: 10px solid #fff;
  }

  .lmpixels-arrows-nav:hover {
    outline: none;
  }

  .lmpixels-arrows-nav {
    bottom: 2rem !important;
    border-radius: 2rem 2rem 5px 5px !important;
    height: 5rem !important;
    /* width: 3rem !important; */
  }

  .chevron {
    /* width: 2rem;
    height: 0.5rem; */
  }
}

@media only screen and (min-width: 1300px) {
  .header {
    max-width: 440px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media only screen and (min-width: 1025px) {

  ul.main-menu {
    width: 100px !important;

  }

  ul.main-menu .nav-anim {
    border-bottom: 1px solid #f5f5f5;
    display: block;
    position: relative;
    color: #b5b6b7;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }
}