   body {
       background-color: #ffffff;
       overflow: hidden;
       font-family: 'Poppins', Helvetica, sans-serif;
   }

   hr {

       margin-bottom: 1rem;
       margin-top: 1rem;
       border: 0;
       border-top: 1px solid rgba(255, 255, 255, 0.1);
   }

   #shutDownPage {
       height: 100vh !important;
       font-family: 'Poppins', Helvetica, sans-serif;
   }


   #shutPageContent {
       z-index: 2;
   }

   span.err-error {
       text-transform: uppercase;
       font-size: 7em;
       /* font-family: 'Titillium Web'; */
       font-weight: 500;
       color: #ffffff;
       letter-spacing: 25px;

   }

   span.err-status {
       text-transform: uppercase;
       font-size: 2em;
       /* font-family: 'Titillium Web'; */
       font-weight: 500;
       color: #a90000;
       letter-spacing: 10px;
   }

   span.err-statuscode {
       text-transform: uppercase;
       font-size: 2em;
       /* font-family: 'Titillium Web'; */
       font-weight: 500;
       color: #ffe000;
       letter-spacing: 10px;
   }

   span.err-junk {
       text-transform: uppercase;
       font-size: 1em;
       /* font-family: 'Titillium Web'; */
       font-weight: 500;
       color: #CECECE;
       letter-spacing: 10px;
   }

   .err-container {
       width: 100%;
       display: block;
       padding: 80px 0;
   }

   button.err-link {
       text-align: center;
       text-transform: uppercase;
       font-size: 1em;
       /* font-family: 'Titillium Web'; */
       font-weight: 500;
       color: #ffe000;
       background: none;
       outline: none;
       border: none;
       letter-spacing: 10px;
       text-decoration: none;
       background-color: unset !important;
       cursor: pointer;

   }

   button.err-link:hover {
       box-shadow: none;
       outline: none;
       border: none;
   }

   button.err-link:hover {
       text-transform: uppercase;
       font-size: 1em;
       /* font-family: 'Titillium Web'; */
       font-weight: 500;
       color: #e9cf0ae5;
       letter-spacing: 10px;
       /* text-decoration:underline; */
   }

   .lm-animated-bg {
       display: block !important;
   }


   @media only screen and (max-width:768px) {
       span.err-error {
           font-size: 4em;
           letter-spacing: 20px;
       }

       span.err-statuscode {
           font-size: 1em;
       }
   }


   @media only screen and (max-width:280px) {
       span.err-error {
           font-size: 2em;
           letter-spacing: 20px;
       }

       .span.err-statuscode {
           line-height: 2;
       }

       button.err-link {
           font-size: 0.6em;
       }
   }